import Vue from 'vue'
import Router from 'vue-router'
import Dashboard from './views/Dashboard.vue'
import Signin from './views/Signin.vue'

Vue.use(Router);

const add_edit_preset_children_routes = function(name) {
    return  [
        {
            path: '',
            name: name,
            component: () => import(/* webpackChunkName: "add-edit-preset-steps" */ './views/Steps.vue')
        },
        {
            path: 'photo-design-settings',
            name: name+'-photo-design-settings',
            component: () => import(/* webpackChunkName: "photo-design-settings" */ './views/PhotoDesignSettings.vue')
        },
        {
            path: 'app-design-settings',
            name: name+'-app-design-settings',
            component: () => import(/* webpackChunkName: "app-design-settings" */ './views/AppDesignSettings.vue')
        },
        {
            path: 'effect-settings',
            name: name+'-effect-settings',
            component: () => import(/* webpackChunkName: "effect-settings" */ './views/EffectSettings.vue')
        },
        {
            path: 'camera-settings',
            name: name+'-camera-settings',
            component: () => import(/* webpackChunkName: "camera-settings" */ './views/CameraSettings.vue')
        },
        {
            path: 'send-settings',
            name: name+'-send-settings',
            component: () => import(/* webpackChunkName: "send-settings" */ './views/SendSettings.vue')
        },
        {
            path: 'print-settings',
            name: name+'-print-settings',
            component: () => import(/* webpackChunkName: "print-settings" */ './views/PrintSettings.vue')
        },
        {
            path: 'data-capture-settings',
            name: name+'-data-capture-settings',
            component: () => import(/* webpackChunkName: "data-capture-settings" */ './views/DataCaptureSettings.vue')
        },
        {
            path: 'save-settings',
            name: name+'-save-settings',
            component: () => import(/* webpackChunkName: "save-settings" */ './views/SaveSettings.vue')
        }
    ];
};

export default new Router({
    routes: [
        {
            path: '/',
            name: 'dashboard',
            component: Dashboard,
            meta: {
                auth: true
            }
        },
        /*{
            path: '/about',
            name: 'about',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/!* webpackChunkName: "about" *!/ './views/About.vue'),
            meta: {
                auth: true
            }
        },*/
        {
            path: '/signin',
            name: 'signin',
            component: Signin,
            meta: {
                auth: false
            }
        },
        {
            path: '/forgot-password',
            name: 'forgot-password',
            component: () => import(/* webpackChunkName: "forgot-password" */ './views/ForgotPassword.vue'),
            meta: {
                auth: false
            }
        },
        {
            path: '/edit-preset/:id',
            component: () => import(/* webpackChunkName: "add-edit-preset" */ './views/AddEditPreset.vue'),
            meta: {
                auth: true
            },
            children: add_edit_preset_children_routes('edit-preset')
        },
        {
            path: '/add-preset',
            component: () => import(/* webpackChunkName: "add-edit-preset" */ './views/AddEditPreset.vue'),
            meta: {
                auth: true
            },
            children: add_edit_preset_children_routes('add-preset')
        },
        {
            path: '/export-data-capture',
            name: 'export-data-capture',
            component: () => import(/* webpackChunkName: "export-data-capture" */ './views/ExportDataCapture.vue'),
            meta: {
                auth: true
            }
        },
        {
            path: '/analytics',
            name: 'analytics',
            component: () => import(/* webpackChunkName: "analytics" */ './views/Analytics.vue'),
            meta: {
                auth: true
            }
        },
        {
            path: '/ck-post-leads',
            name: 'ck-post-leads',
            component: () => import(/* webpackChunkName: "ck-post-leads" */ './views/CkPostLeads.vue'),
            meta: {
                auth: true
            }
        },
        {
            path: '/gallery/:id',
            name: 'gallery',
            component: () => import(/* webpackChunkName: "gallery" */ './views/Gallery.vue'),
            meta: {
                auth: false
            }
        },
        {
            path: '*',
            name: '404',
            component: () => import(/* webpackChunkName: "404" */ './views/404.vue'),
            meta: {
                auth: false
            }
        },
        {
            path: '/register-p',
            name: 'register',
            component: () => import(/* webpackChunkName: "register-p" */ './views/Register.vue'),
            meta: {
                auth: false
            }
        },
        {
            path: '/register',
            name: 'signup',
            component: () => import(/* webpackChunkName: "signup" */ './views/SignUp.vue'),
            meta: {
                auth: false
            }
        }
    ]
})
