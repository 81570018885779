<template>
    <div id="app">
        <top-nav v-if="$auth.check() && $route.name !== 'register'"></top-nav>
        <router-view ref="mainView"/>
    </div>
</template>

<script>
    // @ is an alias to /src
    import TopNav from '@/components/TopNav.vue'

    export default {
        components: {
            TopNav
        }
    }
</script>