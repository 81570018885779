import {Auth} from "aws-amplify";

export default {
    install(Vue, opts) {

        const options = { ...{debug: false, authenticated: false, user: null}, ...opts };

        Vue.axios.interceptors.request.use((config) => {
            return Auth.currentSession()
                .then(function (data) {
                    config.headers.authorization = `Bearer ${data.getAccessToken().getJwtToken()}`;
                    return Promise.resolve(config);
                })
                .catch(function (err) {
                    // eslint-disable-next-line
                    if( options.debug === true ) console.log(err);
                    return Promise.resolve(config);
                });
        });

        let $auth;
        Vue.prototype.$auth = $auth = new Vue( {
            data: function() {
                return {
                    'authenticated': options.authenticated,
                    'user': options.user,
                }
            },
            methods: {
                check: function() {
                    return this.authenticated;
                },
                logout: function() {
                    const app = this;
                    Auth.signOut()
                        .then(function(){
                            app.authenticated = false;
                            app.user = null;
                        })
                        .catch(function(err){
                            // eslint-disable-next-line
                            if( options.debug === true ) console.log(err);
                        }).finally(function() {
                            Vue.router.push({name: 'signin'});
                        });
                }
            }
        });

        Vue.router.beforeEach((to, from, next) => {

            if( ( to.name === 'signin' || to.name === 'forgot-password' || to.name === 'signup' ) && $auth.check() ) {

                next({
                    path: '/'
                });

            } else if (to.matched.some(record => record.meta.auth)) {

                if( !$auth.check() ) {

                    next({
                        name: 'signin'
                    });

                } else {

                    next();

                }

            } else {

                next();

            }
        });

        Vue.axios.interceptors.response.use((response) => {
            return response;
        }, (error) => {
            if (401 === error.response.status || 403 === error.response.status) {
                if(Vue.router.currentRoute.name !== 'register') {
                    $auth.logout();
                }
                return Promise.reject(error);
            } else {
                return Promise.reject(error);
            }
        });

    }
}