import Vue from 'vue';
import App from './App.vue';

import axios from 'axios';
import VueAxios from 'vue-axios';

import router from './router';
//import store from './store';

import '@/assets/css/tailwind.css';

import Amplify, {Auth} from 'aws-amplify';

Amplify.configure({
    Auth: {
        identityPoolId: 'eu-west-2:9999b734-6c7c-41c2-a901-882333fd8200',
        userPoolId: 'eu-west-2_QpxCxi81N',
        userPoolWebClientId: '287dce1geavk7vo9prkhovej15',
        region: 'eu-west-2'
    }/*,
    Storage: {
        AWSS3: {
            bucket: 'boothclub-client-media',
            region: 'eu-west-2'
        }
    }*/
});

import AuthPlugin from './auth';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faEnvelope, faLock, faEye, faEyeSlash, faPlus, faTimesCircle, faShareSquare, faCheck, faDownload, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faCheckSquare, faTrashAlt, faCopy } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faEnvelope, faLock, faEye, faEyeSlash, faPlus, faTimesCircle, faCheckSquare, faShareSquare, faCheck, faDownload, faTrashAlt, faChevronLeft, faChevronRight, faCopy);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(VueAxios, axios);
Vue.axios.defaults.baseURL = process.env.VUE_APP_BOOTHCLUBAPP_API_ENDPOINT;
//Vue.axios.defaults.withCredentials = true; //needed only if server cookie required

Vue.router = router; //Explicitly define vue.router for auth

Vue.config.productionTip = false;

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard);


Auth.currentAuthenticatedUser({

})
    .then(function(user) {

        Vue.use(AuthPlugin, {debug: process.env.NODE_ENV === 'development', authenticated: true, user: user  });

    })
    .catch(function(err) {

        Vue.use(AuthPlugin, {debug: process.env.NODE_ENV === 'development', authenticated: false, user: null  });
        // eslint-disable-next-line
        if( process.env.NODE_ENV === 'development' ) console.log(err);

    }).finally(function() {

        new Vue({
            router,
            //store,
            render: h => h(App)
        }).$mount('#app');

    });