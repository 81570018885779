<template>
    <div class="bg-black" id="navigation">
        <ul class="flex justify-end px-5 text-3xl uppercase font-bold tracking-wider" :class="{'justify-between' : $route.name === 'export-data-capture' || $route.name === 'dashboard' || $route.name === 'analytics' || $route.name === 'ck-post-leads' || $route.name === 'gallery'}">
            <template v-if="$route.name === 'edit-preset' || $route.name === 'add-preset'">
<!--                <li><router-link to="/">Back</router-link></li>-->
                <li><a href="#" v-on:click.prevent="$root.$emit('savepreset')">Save & Exit</a></li>
            </template>
            <template v-else>
                <li v-if="$route.name==='export-data-capture' || $route.name==='analytics'"><router-link to="/">Presets</router-link></li>
                <li v-else>
                    <router-link :to="{name: 'dashboard'}" v-if="$route.name === 'ck-post-leads' || $route.name==='gallery'" class="mr-6">Presets</router-link>
                    <router-link :to="{name: 'export-data-capture'}">Export Data</router-link>
                    <router-link :to="{name: 'analytics'}" class="ml-6">Analytics</router-link>
                </li>
                <li><a href="#" v-on:click.prevent="$auth.logout()">Logout</a></li>
            </template>
        </ul>
    </div>
</template>

<script>
    export default {
        name: 'TopNav'
    }
</script>