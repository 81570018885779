<template>
    <transition :name="transition">
        <div
                v-if="showing"
                class="fixed inset-0 w-full h-screen flex items-center justify-center"
         style="z-index: 1001;" :class="overlayBgClass">
            <div class="relative max-h-screen w-full max-w-md shadow-lg p-8 flex" :class="[modalBgClass, borderClass]">
                <button
                        aria-label="close"
                        class="absolute top-0 right-0 text-3xl text-white my-2 mx-4 uppercase font-bold tracking-wider focus:outline-none"
                        @click.prevent="close"
                v-if="closeBtn">
                    Close
                </button>
                <div class="max-h-screen w-full pt-10 pb-6 px-6">
                    <slot />
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        props: {
            showing: {
                required: true,
                type: Boolean
            },
            transition: {
                type: String,
                default: 'fade'
            },
            closeBtn: {
                type: Boolean,
                default: true
            },
            overlayBgClass: {
                type: String,
                default: 'bg-transparent-white'
            },
            modalBgClass: {
                type: String,
                default: 'bg-black'
            },
            borderClass:{
                type: String,
                default: ''
            }
        },
        mounted: function() {
            this.$root.$el.append(this.$el);
        },
        destroyed: function() {
            this.$el.parentNode.removeChild(this.$el);
        },
        watch: {
            showing(value) {
                if (value) {
                    //return document.querySelector('body').classList.add('overflow-hidden');
                }

                //document.querySelector('body').classList.remove('overflow-hidden');
            }
        },
        methods: {
            close() {
                this.$emit('close');
            }
        }
    };
</script>
<style scoped>
    .fade-enter-active,
    .fade-leave-active {
        transition: all 0.6s;
    }
    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }

    .scale-enter,
    .scale-leave-active {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }

    .scale-enter-active,
    .scale-leave-active {
        transition: all .3s ease;
    }
    .scale-enter,
    .scale-leave-to {
        opacity: 0;
    }
</style>