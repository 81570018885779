<template>
    <div class="container mx-auto">

        <h1 class="mt-8 md:mt-20 px-6 md:px-16 font-bold text-40px uppercase tracking-wider">Your Presets</h1>

        <div class="mt-6 md:mt-16 px-6 md:px-16" v-if="!presets">
            <list-loader :width="400" :height="160" :speed="1" :animate="true" primaryColor="lightgray" secondaryColor="gray"></list-loader>
        </div>

        <div v-else class="flex flex-wrap -mx-3 mt-6 md:mt-16 px-6 md:px-16">

            <div class="w-full md:w-1/3 lg:w-1/4 px-3 mb-6">
                <div :class="['bg-black border-b-3 border-turquoise flex items-center justify-center text-center h-64', {'md:h-full' : presets.length > 0}]">
                    <router-link to="/add-preset">
                        <font-awesome-icon icon="plus" class="text-4xl"></font-awesome-icon>
                        <div class="text-4xl uppercase font-bold tracking-wider">New Preset</div>
                    </router-link>
                </div>
            </div>

            <div v-for="(preset, key) in presets" v-bind:key="preset.configurationID" :class="['w-full md:w-1/3 lg:w-1/4 px-3 mb-6 overflow-hidden preset-column', {'delete-me' : preset.is_deleted}, {'opacity-75 cursor-not-allowed' : preset.is_deleting_me}, {'new-cloned' : preset.is_new_cloned}]">
                <div class="border-b-3 border-turquoise preset-image relative overflow-y-hidden text-black text-3xl text-center uppercase font-bold tracking-medium leading-tight lg:text-4xl">
<!--                    <img class="absolute opacity-25" v-if="preset.photoDesignSettings && preset.photoDesignSettings.overlayImage" :src="api_url+'/media/'+preset.photoDesignSettings.overlayImage" alt="" />-->
<!--                    <img class="absolute" v-else src="../assets/images/image_placeholder.jpg" alt="" />-->
                    <img class="absolute  opacity-25" src="../assets/images/image_overlay_background.png" alt="" />
                    <span class="absolute top-50 left-50 -translate-50">{{preset.title ? preset.title : preset.displayDate}}</span>
                </div>
                <div class="uppercase text-xl tracking-medium border-b border-gray-custom-2 leading-custom pt-1">
                    {{preset.displayDate}}
                </div>
                <!--<div class="clearfix mb-2 leading-custom">
                    <div class="float-left text-xl tracking-medium uppercase">
                        {{preset.displayDate}}
                    </div>
                    <div class="float-right text-xl tracking-medium uppercase">
                        0 Photos
                    </div>
                </div>-->
                <div class="clearfix pt-3">
                    <div class="float-left w-1/2 pr-5px">
                        <router-link :to="'/edit-preset/'+preset.configurationID" class="bg-white text-black font-dense tracking-medium text-xl font-bold border-b-3 border-turquoise leading-snug pt-2 pb-1 outline-none focus:bg-turquoise block text-center">EDIT</router-link>
                    </div>
                    <div class="float-right w-1/2 pl-5px">
                        <a href="#" class="bg-white text-black font-dense tracking-medium text-xl font-bold border-b-3 border-turquoise leading-snug pt-2 pb-1 outline-none focus:bg-turquoise block text-center" @click.prevent="clonePreset(key, $event)">CLONE</a>
                    </div>
                </div>
                <div class="clearfix mt-2">
                    <div class="float-left w-1/2 pr-5px" v-if="preset.saveSettings && preset.saveSettings.saveToAWS=='true'">
                        <router-link :to="'/gallery/'+preset.configurationID" class="bg-white text-black font-dense tracking-medium text-xl font-bold border-b-3 border-turquoise leading-snug pt-2 pb-1 outline-none focus:bg-turquoise block text-center">GALLERY</router-link>
                    </div>
                    <div :class="[preset.saveSettings && preset.saveSettings.saveToAWS == 'true' ? 'float-right w-1/2 pl-5px' : 'w-full']">
                        <a href="#" class="bg-white text-black font-dense tracking-medium text-xl font-bold border-b-3 border-turquoise leading-snug pt-2 pb-1 outline-none focus:bg-turquoise block text-center text-red-600" @click.prevent="deletePreset(key)">DELETE</a>
                    </div>
                </div>


            </div>

        </div>

        <modal :showing="showModal" transition="scale" @close="showModal = delete_error = clone_error = false" class="text-center">
            <template v-if="delete_error">
            <div class="text-white text-2xl sm:text-4xl uppercase leading-tight font-bold tracking-wide">Error deleting preset.<br>Please try again!</div>
            </template>
            <template v-else-if="clone_error">
                <div class="text-white text-2xl sm:text-4xl uppercase leading-tight font-bold tracking-wide">Error duplicating preset.<br>Please try again!</div>
            </template>
            <template v-else>
            <div class="text-white text-2xl sm:text-4xl uppercase leading-tight font-bold tracking-wide">Are you sure you want<br>to delete this preset?</div>
            <br>
            <div class="flex -mx-2">
                <a href="#" class="bg-white text-black font-dense tracking-wider text-3xl font-bold border-b-3 border-turquoise leading-tight pt-2 pb-1 outline-none focus:bg-turquoise w-1/2 mx-2" @click.prevent="confirmDelete">YES</a>
                <a href="#" class="bg-white text-black font-dense tracking-wider text-3xl font-bold border-b-3 border-turquoise leading-tight pt-2 pb-1 outline-none focus:bg-turquoise w-1/2 mx-2" @click.prevent="cancelDelete">NO</a>
            </div>
            </template>
        </modal>

    </div>
</template>

<script>

    import { ListLoader } from "vue-content-loader";
    import Modal from '@/components/Modal.vue';

    export default {
        name: 'Dashboard',
        data() {
            return {
                has_error: false,
                presets: null,
                api_url: process.env.VUE_APP_BOOTHCLUBAPP_API_ENDPOINT,
                showModal: false,
                delete_configuration_key: null,
                delete_error: false,
                clone_error: false
            }
        },
        components: {
            ListLoader,
            Modal
        },
        mounted() {
            this.getPresets();
        },
        methods: {
            getPresets() {
                const app = this;
                this.$http({
                    url: 'presets',
                    method: 'get'
                })
                    .then(function(response) {
                        app.presets = response.data.presets;
                    })
                    .catch(function(error){
                        app.has_error = error.response.data.message;
                    })
            },
            deletePreset(configuration_key) {
                this.delete_configuration_key = configuration_key;
                this.showModal = true;
            },
            cancelDelete() {
                this.delete_configuration_key = null;
                this.showModal = false;
            },
            confirmDelete() {
                const app = this,
                    delete_configuration_key = this.delete_configuration_key;
                this.delete_configuration_key = null;
                this.showModal = false;
                app.$set(app.presets[delete_configuration_key], 'is_deleting_me', true);
                this.$http({
                    url: 'delete-preset/'+this.presets[delete_configuration_key].configurationID,
                    method: 'delete'
                })
                    .then(function() {
                        app.$set(app.presets[delete_configuration_key], 'is_deleted', true);
                        setTimeout(function() {
                            app.$delete(app.presets, delete_configuration_key);
                        }, 1000);
                    })
                    .catch(function() {
                        app.delete_error = true;
                        app.$set(app.presets[delete_configuration_key], 'is_deleting_me', false);
                        app.showModal = true;
                    });
            },
            clonePreset(configuration_key, event) {
                const app = this;
                this.clone_error = false;
                this.showModal = false;
                this.$http({
                    url: 'clone-preset',
                    method: 'post',
                    data: {
                        configuration_id: this.presets[configuration_key].configurationID
                    }
                })
                    .then(function(response) {
                        app.presets.unshift(response.data.preset);
                        setTimeout(function() {
                            app.$set(app.presets[0], 'is_new_cloned', false);
                            event.target.blur();
                        }, 500);
                    })
                    .catch(function(){
                        app.clone_error = true;
                        app.showModal = true;
                    });
            }
        }
    }

</script>

<style scoped>
    .preset-column {
        transition: all 1s;
        transform: scale(1);
    }
    .new-cloned {
        transform: scale(0) !important;
        width: 0 !important;
        padding: 0 !important;
    }
    .delete-me {
        animation: hide-me 1s forwards;
    }
    @keyframes hide-me {
        100% {
            height: 0;
            width: 0;
            padding: 0;
        }
    }
</style>