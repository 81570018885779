<template>
    <div class="flex h-screen items-center justify-center">
        <div class="w-full max-w-xs px-2 sm:px-0">
            <router-link to="/"><img alt="Boothclub" src="../assets/images/Boothclub-logo.png"></router-link>
            <h1 class="text-50px font-bold tracking-4px mt-8 mb-12">SIGN IN</h1>
            <form autocomplete="off" class="font-work-sans" v-on:submit.prevent="signIn" method="post">
                <div class="relative mb-12">
                    <label for="email" class="text-sm tracking-wider text-gray-custom absolute block top-0"><font-awesome-icon icon="envelope" class="text-xl pr-1 align-top"></font-awesome-icon> Email address</label>
                    <input type="email" name="email" id="email" class="bg-transparent w-full outline-none border-b border-gray-custom pb-2 font-work-sans-medium  tracking-wider text-xl" v-on:focus="inputFocus" v-on:blur="inputBlur" required v-model="email">
                </div>
                <div class="relative mb-4">
                    <label for="password" class="text-sm tracking-wider text-gray-custom absolute block top-0"><font-awesome-icon icon="lock" class="text-xl pr-1 align-top"></font-awesome-icon> Password</label>
                    <input :type="password_input_type" name="password" id="password" :class="['bg-transparent w-full outline-none border-b border-gray-custom pb-2 font-work-sans-medium tracking-wider text-xl', password_input_color_class, {'text-red-600' : incorrect_password}]" v-on:focus="inputFocus" v-on:blur="inputBlur" autocomplete="off" required v-model="password">
                    <div v-if="incorrect_password" class="text-red-600 absolute -top-30 block right-0 text-sm">Incorrect password</div>
                    <button type="button" :class="['absolute top-0 block right-0 text-xs', password_input_color_class]" v-on:click="togglePasswordInput"><font-awesome-icon icon="eye" v-if="this.password_input_type==='password'"></font-awesome-icon><font-awesome-icon icon="eye-slash" v-else></font-awesome-icon></button>
                </div>
                <div class="text-sm mb-6 tracking-wider">
                    <router-link :to="{ name: 'forgot-password' }">Forgotten your password?</router-link>
                </div>
                <!-- <div class="mb-6">
                    <vue-recaptcha sitekey="6LemglkhAAAAABafj-4tsR-zUnkeX9_j9sIgfoof" @verify="onCaptchaVerified"></vue-recaptcha>
                </div> -->
                <div>
                    <button class="bg-white text-black font-dense tracking-wider text-4xl font-bold w-full border-b-3 border-turquoise leading-tight pt-2 outline-none focus:bg-turquoise" type="submit">SIGN IN</button>
                </div>
                <div v-show="other_error" class="text-red-600 mt-8 text-sm text-center break-words" >{{ other_error }}</div>
            </form>
        </div>
    </div>
</template>

<script>
    import { Auth } from 'aws-amplify';
    //import { VueRecaptcha } from 'vue-recaptcha';

    export default {
        name: 'Signin',
        data: function() {
            return {
                email: null,
                password: null,
                password_input_type: 'password',
                password_input_color_class: '',
                incorrect_password: false,
                other_error: false,
                recaptchaToken: null,
            }
        },
        components: {
            //VueRecaptcha
        },
        methods: {
            inputFocus: function(event) {
                if( event.target.value === '' ) {
                    event.target.previousElementSibling.style.top = '-30px';
                }
            },
            inputBlur: function(event) {
                if( event.target.value === '' ) {
                    event.target.previousElementSibling.style.top = '0';
                }
            },
            togglePasswordInput: function() {
                this.password_input_type = this.password_input_type === 'password' ? 'text' : 'password';
                this.password_input_color_class = this.password_input_type === 'password' ? '' : 'text-turquoise';
            },
            signIn: function() {

                const app = this;
                app.incorrect_password = false;
                app.other_error = false;

                (async function() {

                    var challenge = false;

                    try {

                        const user = await Auth.signIn(app.email, app.password, {recaptchaToken: app.recaptchaToken});

                        if (user.challengeName === 'SMS_MFA' ||
                            user.challengeName === 'SOFTWARE_TOKEN_MFA') {

                            // You need to get the code from the UI inputs
                            // and then trigger the following function with a button click
                            // const code = getCodeFromUserInput();
                            // If MFA is enabled, sign-in should be confirmed with the confirmation code
                            /*const loggedUser = await Auth.confirmSignIn(
                                user,   // Return object from Auth.signIn()
                                code,   // Confirmation code
                                mfaType // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
                            );*/
                            challenge = 'User not confirmed.';
                        } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                            /*const { requiredAttributes } = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']*/
                            // You need to get the new password and required attributes from the UI inputs
                            // and then trigger the following function with a button click
                            // For example, the email and phone_number are required attributes
                            /*const { username, email, phone_number } = getInfoFromUserInput();
                            const loggedUser = await Auth.completeNewPassword(
                                user,               // the Cognito User Object
                                newPassword,       // the new password
                                // OPTIONAL, the required attributes
                                {
                                    email,
                                    phone_number,
                                }
                            );*/
                            challenge = 'Password reset required.';
                        } else if (user.challengeName === 'MFA_SETUP') {
                            // This happens when the MFA method is TOTP
                            // The user needs to setup the TOTP before using it
                            // More info please check the Enabling MFA part
                            //Auth.setupTOTP(user);
                            challenge = 'user needs to setup the TOTP.';
                        } else {
                            // The user directly signs in
                            app.$auth.authenticated = true;
                            app.$auth.user = user;
                            const redirectTo = 'dashboard';
                            app.$router.push({name: redirectTo});

                        }

                    } catch (err) {
                        if (err.code === 'UserNotConfirmedException') {
                            // The error happens if the user didn't finish the confirmation step when signing up
                            // In this case you need to resend the code and confirm the user
                            // About how to resend the code and confirm the user, please check the signUp part
                            app.other_error = 'Email not verified. You must verify before you can log in.';
                        } else if (err.code === 'PasswordResetRequiredException') {
                            // The error happens when the password is reset in the Cognito console
                            // In this case you need to call forgotPassword to reset the password
                            // Please check the Forgot Password part.
                            app.other_error = 'Password reset required.';
                        } else if (err.code === 'NotAuthorizedException') {
                            // The error happens when the incorrect password is provided
                            app.incorrect_password = true;
                        } else if (err.code === 'UserNotFoundException') {
                            // The error happens when the supplied username/email does not exist in the Cognito user pool
                            app.incorrect_password = true;
                        } else if(err.code === 'UserLambdaValidationException') {
                             app.other_error = 'Captcha verification failed. Please prove you are a human by checking "I\'m not a robot" checkbox';
                        } else {
                            //console.log(err);
                            app.other_error = err.message;
                        }
                    }

                    if( challenge !== false ) {
                        app.other_error = challenge;
                    }

                })();

            },
            onCaptchaVerified: function(recaptchaToken) {
                this.recaptchaToken = recaptchaToken;
            }
        }
    }
</script>

<style scoped>
    label {
        transition: top 0.2s ease-out;
    }
</style>